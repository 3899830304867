import React from "react"
import Img from "gatsby-image/withIEPolyfill"

import { RevealImageWrap } from "../styles/common.styles"
import { unoptimzeGatsbyImageSource } from "../helpers/common"

export const RevealImage = ({ image, variation, delay }) => {
  const variations = {
    "from-left": "0% 50%",
    "from-right": "100% 50%",
  };
  const initialDeplay = 800;
  
  if (!image) {
    return (
      <React.Fragment></React.Fragment>
    )
  }

  return (
    <RevealImageWrap className={variation}>
      <div className="rev-bg" style={{ transitionDelay: `${(delay || 0)}ms` }}></div>
      <div className="rev-img-wrap" style={{ transitionDelay: `${initialDeplay + (delay || 0)}ms` }}>
        <Img fluid={unoptimzeGatsbyImageSource(image)} objectPosition={variations[variation || 'from-left']} style={{ transitionDelay: `${initialDeplay + (delay || 0)}ms` }} />
      </div>
    </RevealImageWrap>
  )
}